



































































































































































































import api from "@/api"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import {
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  ServiceOrganizationDto,
  AuditFlowScope, SelectListItem, UserAuditInput, HelpAppliesDto, AuditUserLogStatus
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import moment from "moment";
import ExportButton from "@/components/ExportButton/index.vue";
import RejectDialog from "@/components/RejectDialog/index.vue";
@Component({
  name: "RecordList",
  components: {
    PagedTableView,
    AgileAuditTag,
    RejectDialog,
    ExportButton
  },
})
export default class ServiceRecordList extends Vue {
  @Ref() readonly pagedTableView!: any;
  @Ref() backDialogBatch!: RejectDialog;
  serviceOrganizationList: ServiceOrganizationDto[] = [];
  serviceObjectList: any = [];

  queryForm: any = {
    volunteerOrgCode: undefined,
    surname: undefined,
    title: undefined,
    serviceOrganizationId: undefined,
    serviceObjectIds: undefined,
    typeId: undefined,
    serviceStartTime: undefined,
    serviceEndTime: undefined,
  };
  exportFieldList = [
    "志愿者编号",
    "姓名",
    "服务标题",
    "服务机构",
    "服务对象",
    "服务次数",
    "服务开始时间",
    "服务结束时间",
    "反馈时间",
    "状态",
    "实际服务时长（分钟）"
  ];

  serviceTypeList: DataDictionaryDto[] = [];
  typeList: SelectListItem[] = [];
  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "AuditStatus",
      })
      .then((res) => {
        this.typeList = res;
      });
  }
  created() {
    this.fetchDataDictionary();
    this.getServiceObjectList()
    this.fetchEnumType()
  }
  async fetchDataDictionary() {
    //服务类型
    await api.dataDictionary.getDataDictionaryListByKey({
      key: 'ServiceType',
      maxResultCount: 65535,
    }).then((res: DataDictionaryDtoPagedResultDto) => {
      this.serviceTypeList = res.items!;
    })

    await api.serviceOrganization.getAllServiceOrganizationList().then((res: any) => {
      this.serviceOrganizationList = res!;
    })
  }

  // 获取表数据
  async fetchData(params: any) {
    if (this.$route.params.serviceId) {
  params.serviceId=parseInt(this.$route.params.serviceId)
    }
    return await api.serviceRecord.getAll(params);
  }

  async getServiceObjectList() {
    await  api.serviceObject.getAll({maxResultCount: 65535}).then((res) => {
      this.serviceObjectList = res.items!;
    })
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ServiceRecord,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "research": {
        this.handleResearch(e.index, e.item);
        return;
      }
    }
  }

  // 查看详情
  jumpDetail(index: number, row: any) {
    this.$router.push({
      name: "serviceRecord-detail",
      params: { id: row.id!.toString() },
    });
  }
  // 查看详情
  jumpOneHundredDays() {
    this.$router.push({
      name: "oneHundredDays",
    });
  }





  selection: UserAuditInput[] = [];

  handleSelectionChange(e: HelpAppliesDto[]) {
    this.selection = [];

    if (e.length > 0) {
      this.selection = e.map((x: HelpAppliesDto) => {
        return {
          hostId: x.id + "",
          auditStatus: x.auditStatus,
          auditFlowId: x.auditFlowId,
        };
      });
      console.log("selection is :", this.selection);
    }
  }

  selectable(row: any, index: any) {
    return row.isCanAudit === true;
  }

  handleBatchAudited() {
    let postData = this.selection.map((x) => {
      x.desc = "批量审核通过";
      x.status = AuditUserLogStatus.Pass;
      return x;
    });
    console.log(postData);
    api.serviceRecord.batchAudit({body: {list: postData}})
      .then(() => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.fetchData(this.queryForm);
      });
  }

  handleBatchReject() {
    (this.backDialogBatch as any).show = true;
  }

  backedBatch(text: string) {
    let postData = this.selection.map((x) => {
      x.desc = text;
      x.status = AuditUserLogStatus.Reject;
      return x;
    });
    console.log(postData);
    api.serviceRecord.batchAudit({body: {list: postData}})
      .then(() => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.fetchData(this.queryForm);
      });
  }
}
